export const FeatureFlagResult = {
  configurableProfileFields: 'configurableProfileFields',
  configurableProfileFieldsVisibility: 'configurableProfileFieldsVisibility',
  coSigningInvites: 'coSigningInvites',
  firebaseCloudMessaging: 'firebaseCloudMessaging',
  hiddenNotes: 'hiddenNotes',
  muteWebNotifications: 'muteWebNotifications',
  orderPreferenceContacts: 'orderPreferenceContacts',
  profileNotes: 'profileNotes',
  roleHiddenNotes: 'roleHiddenNotes',
  selfServeAuditTrail: 'selfServeAuditTrail',
  sessionTimeout: 'sessionTimeout',
  ldapDirectorySync: 'ldapDirectorySyncClient',
  profileAddressLabel: 'profileAddressLabel',
  paginatedContacts: 'paginatedContacts',
  templatedMessagesReportButton: 'templatedMessagesReportButton',
  pcSiteFilters: 'pcSiteFilters',
  newSearchableDepartmentAndUserList: 'newSearchableDepartmentAndUserList',
  singleTenantAccountFlag: 'singleTenantAccountFlag',
  escalationFeatureFlag: 'escalationFeatureFlag',
  optimizeOnCallNetworkRequest: 'optimizeOnCallNetworkRequest',
  optimizeChatGraphQLFragments: 'optimizeChatGraphQlFragments',
  optimizeWebApp: 'optimizeWebApp',
  staticMaintenancePage: 'staticMaintenancePage',
  missingAddressBanner: 'missingAddressBanner',
  urgentMessageLabel: 'urgentMessageLabel',
  statMessageLabel: 'statMessageLabel',
} as const;
